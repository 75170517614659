import ThumbnailsComponent from '@wix/thunderbolt-elements/src/components/Thumbnails/viewer/Thumbnails';
import ThumbnailsController from '@wix/thunderbolt-elements/src/components/Thumbnails/viewer/Thumbnails.controller';


const Thumbnails = {
  component: ThumbnailsComponent,
  controller: ThumbnailsController
};


export const components = {
  ['Thumbnails']: Thumbnails
};


// temporary export
export const version = "1.0.0"
